<template>
    <div>
        <v-toolbar
                dense
                tabs
                color="primary darken-2"
                dark
        >
            <v-toolbar-title class="pl-2">Signup</v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 offset-md2>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <v-card class="blue lighten-4" style="" >
                                <v-card-text style="font-size: 12px; ">
                                    <p class="dark--text subheading pa-0 ma-0 text-xs-center"><span class="success--text"> Congrats!!!</span> your account has been registered successfully,  activate it by confirming your phone number </p>
                                </v-card-text>
                            </v-card>

                            <v-card color="white pa-3 " style="background-color: rgb(236, 243, 250)!important" >
                                <b>Step 2/4: Confirm Phone Number</b>
                                <v-layout row>
                                    <v-flex xs12 md10 offset-md1>            
                                        <v-text-field class="pa-0 mt-1"
                                            id="phone_number"
                                            label="Mobile No: +237 678113152"
                                            single-line disabled
                                            color="teal"
                                        ></v-text-field>
                                        <div class=" text-xs-center">
                                            <v-btn color="teal" small outline>Send verification SMS </v-btn>
                                        </div>
                                        <br><hr>
                                        <div class=" text-xs-center mt-0">
                                            <p>A 6-digit code has been sent to your number, input it below to proceed</p>
                                        </div>

                                        <v-text-field class="pa-0 mt-1"
                                            id="password"
                                            label="Enter 6-digit Code"
                                            single-line
                                            color="teal"
                                        ></v-text-field>

                                    </v-flex>
                                </v-layout>
                                <div class=" text-xs-right">
                                    <v-btn class="white--text" round color="teal darken-2" :to="{name: 'signup_three'}">
                                        <span> Validate</span>
                                    </v-btn>
                                </div>
                            </v-card>

                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {}
</script>
